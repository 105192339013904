export default [
  {
    id: 1,
    group: 'Default',
    color: 'grey-5',
    buttons: [
      {
        id: 'pending_queued',
        title: 'Pending Queued',
        color: 'grey-4',
        editableField: false,
        transitions: []
      },
      {
        id: 'cancel',
        title: 'Cancel',
        color: 'grey-5',
        editableField: false,
        transitions: ['pending_queued']
      }
    ]
  },
  {
    id: 2,
    group: 'Warning',
    color: 'yellow',
    buttons: [
      {
        id: 'new',
        title: 'New/Pending',
        color: 'yellow-3',
        editableField: true,
        transitions: ['pending_queued', 'reserve_queued', 'cancel', 'new' , 'download']
      },
      {
        id: 'pending',
        title: 'Pending',
        color: 'yellow-4',
        editableField: true,
        transitions: ['pending_queued', 'reserve_queued', 'cancel', 'new' , 'download']
      },
      {
        id: 'pending_error',
        title: 'Pending Error',
        color: 'yellow-5',
        editableField: true,
        transitions: ['pending_queued', 'new', 'pending_queued', 'reserve_queued', 'cancel', 'download']
      },
      {
        id: 'error',
        title: 'Error',
        color: 'yellow-5',
        editableField: true,
        transitions: ['pending_queued', 'new', 'pending_queued', 'reserve_queued', 'cancel', 'download', 'assembled',]
      },
      {
        id: 'partly_reserved',
        title: 'Partly Reserved',
        color: 'yellow-6',
        editableField: true,
        transitions: ['reserve_queued', 'cancel']
      },
      {
        id: 'download',
        title: 'Download',
        color: 'yellow-8',
        editableField: false,
        transitions: ['new' , 'pending']
      },
      {
        id: 'deleted',
        title: 'Deleted',
        color: 'yellow-7',
        editableField: false,
        transitions: []
      }
    ]
  },
  // {
  //   id: 3,
  //   group: 'Danger',
  //   color: 'danger',
  //   buttons: []
  // },
  {
    id: 4,
    group: 'Reserve',
    color: 'green',
    buttons: [
      {
        id: 'reserve_queued',
        title: 'Reserve Queued',
        color: 'green-12',
        editableField: false,
        transitions: []
      },
      {
        id: 'reserved',
        title: 'Reserved',
        color: 'green-13',
        editableField: true,
        transitions: ['pending_queued', 'reserve_queued', 'cancel', 'pending', 'new', 'confirmed']
      },
      {
        id: 'confirmed',
        title: 'Confirmed/Awaiting fulfillment',
        color: 'green-14',
        editableField: true,
        transitions: ['pending_queued', 'reserve_queued', 'cancel', 'pending', 'new', 'reserved', 'assembling', 'assembled']
      }
    ]
  },
  {
    id: 5,
    group: 'Prepearing',
    color: 'success',
    buttons: [
      {
        id: 'assembling',
        title: 'In process of assembling',
        color: 'teal-12',
        editableField: false,
        transitions: ['assembled', 'cancel']
      },
      {
        id: 'assembled',
        title: 'Assembled/Ready to ship',
        color: 'teal-13',
        editableField: false,
        transitions: ['cancel', 'delivery', 'complete']
      },
      {
        id: 'processing',
        title: 'Processing',
        color: 'teal-14',
        editableField: true,
        transitions: ['cancel']
      }
    ]
  },
  {
    id: 6,
    group: 'Delivery',
    color: 'info',
    buttons: [
      {
        id: 'delivery',
        title: 'Delivery',
        color: 'light-blue-12',
        editableField: false,
        transitions: ['cancel', 'complete', 'return', 'party_return']
      }
    ]
  },
  {
    id: 7,
    group: 'Return',
    color: 'purple',
    buttons: [
      {
        id: 'return',
        title: 'Return',
        color: 'deep-purple-3',
        editableField: false,
        transitions: []
      },
      {
        id: 'partly_return',
        title: 'Partly Return',
        color: 'deep-purple-4',
        editableField: false,
        transitions: ['return']
      }
    ]
  },
  {
    id: 8,
    group: 'Return',
    color: 'inverse',
    buttons: [
      {
        id: 'complete',
        title: 'Complete',
        color: 'inverse',
        editableField: false,
        transitions: ['returned_partly', 'return']
      }
    ]
  }
]
